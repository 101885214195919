export const PaymentContent = [
  {
    subTitle:
      "This graph is comparing the full monthly mortgage payments of each scenario.",
    propertyAppreciation: "4%",
    propertyDescription:
      "This section overviews an estimate of your monthly payments for each prospective mortgage plan.\n" +
      "Note that the payment may include all applicable taxes and insurance.",
  },
  {
    subTitle:
      "This graph shows the interest{_MI_}for each loan scenario over a {_period_}-year period.",
    propertyAppreciation: "4%",
    propertyDescription:
      "This section overviews an estimate of your long term payments for each prospective mortgage plan.",
  },
  {
    subTitle:
      "This graph shows the interest{_MI_}for each loan scenario over a {_period_}-year period.",
    propertyAppreciation: "4%",
    propertyDescription:
      "This section overviews an estimate of your long term payments for each prospective mortgage plan.",
  },
];

export const ColumnsTable = {
  monthly: [
    { title: "Loan Amount", key: "loanAmount", format: "currency" },
    { title: "Interest Rate", key: "interestRate", format: "percent" },
    { title: "*APR", key: "apr", format: "percent" },
    { title: "Loan Type", key: "loanType" },
    { title: "Term", key: "term" },
    {
      title: "PMI",
      key: "pmi",
      info: "Private Mortgage Insurance",
      format: "currency",
    },
    { title: "Payment", key: "payment", format: "currency" },
    {
      title: "HOA",
      key: "hoa",
      info: "Homeowners association",
      format: "currency",
    },
    {
      title: "Total Payment",
      key: "totalPayment",
      total: true,
      format: "currency",
    },
  ],
  year: [
    {
      title: "Est. Home Value",
      key: "equity",
      format: "currency",
      movement: true
    },
    {
      title: "Purchase Price",
      key: "homeValue",
      format: "currency"
    },
    {
      title: "Total P&I Pmt",
      key: "totalPIPmt",
      format: "currency"
    },
    {
      title: "Principal Paid",
      key: "totalPrincipal",
      format: "currency"
    },
    {
      title: "Balance Remaining",
      key: "loanBalance",
      info: "Total amount paid toward loan balance",
      format: "currency",
    },
    {
      title: "Int. & MI Paid",
      key: "totalIntMi",
      format: "currency",
    },
    {
      title: "Closing Costs",
      key: "closingCost",
      format: "currency",
    },
    {
      title: "Total Cost",
      key: "totalCost",
      format: "currency",
    },
    {
      title: "Net Savings",
      key: "netSaving",
      format: "currency",
      total: true,
    },
  ],
};
